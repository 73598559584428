<template>
<v-row>
  <v-col cols="12" md="2">
    <v-text-field label="* Código"
                  dense
                  :rules="[
                      this.$validators.number.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 2)
                  ]"
                  v-model.trim="value.cdOrgao"/>
  </v-col>  
  <v-col cols="12" md="6">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                           v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 200)
                  ]"
                  v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" md="4">
    <v-text-field label="* CNPJ"
                  dense
                  placeholder="99.999.999/9999-99"
                  :rules="[
                    this.$validators.string.required,
                    this.$validators.string.cnpj
                  ]"
                  v-mask="'##.###.###/####-##'"
                  v-model.trim="value.cnpj"/>
  </v-col>
  <v-col cols="12">
    <strong>Contato</strong>
    <v-divider/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-text-field label="Email"
                  dense
                  :rules="[
                    this.$validators.string.email,
                    v => this.$validators.string.lessThanOrEquals(v, 50),
                ]"
                v-model.trim="value.email"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-text-field label="Telefone"
                  dense
                  v-mask="'(##) ####-####'"
                  v-model.trim="value.telefone"/>
  </v-col>
  <v-col cols="12">
    <strong>Localização</strong>
    <v-divider/>
  </v-col>  
  <v-col cols="12" sm="3">
    <v-text-field label="* CEP"
                  dense
                  v-mask="'########'"
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 8),
                    v => this.$validators.string.lessThanOrEquals(v, 8),
                  ]"
                  v-model.trim="value.cep"/>
  </v-col>
  <v-col cols="12" sm="3">
    <v-text-field label="* Cidade"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                :value="value.cidade"/>
  </v-col>
  <v-col cols="12" sm="3">
    <lookup-dominio label="* Estado"
                    :hidden-clear="true"
                    :value="value.estado"
                    :type="lookups.estado"
                    :rules="[
                        this.$validators.string.required
                    ]"/>
  </v-col>    
  <v-col cols="12" sm="3">
    <v-text-field label="* Bairro"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.bairro"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="* Endereço"
                :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.endereco"/>
    <v-text-field v-else label="* Endereço"
                  dense
                  :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.endereco"/>
  </v-col>
  <v-col cols="12" sm="6">
    <v-textarea v-if="$vuetify.breakpoint.mobile"
                dense
                rows="3"
                label="Complemento"
                :rules="[
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.complemento"/>
    <v-text-field v-else label="Complemento"
                  dense
                  :rules="[
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 100),
                ]"
                v-model.trim="value.complemento"/>
  </v-col>
</v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {buscaEnderecoViaCep} from '../../../api/correios';
import Vue from 'vue';

export default {
  name: "OrgaoForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        estado: ItemDeDominio.COMBO_ESTADO, 
      },
      loading: false,
      error: null,
    }
  },
  watch: {
    'value.cep': {
      handler(newValue, oldValue) {
        this.buscaEndereco();
      }
    }
  },
  methods: {
    async buscaEndereco(){
      if (this.value.cep !== "" && this.value.cep.length === 8){
        let endereco = await buscaEnderecoViaCep(this.value.cep);
        Vue.set(this.value,'cidade',endereco.localidade);
        Vue.set(this.value,'estado',endereco.uf);
        Vue.set(this.value,'bairro',endereco.bairro);
        Vue.set(this.value,'endereco',endereco.logradouro);
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>