var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":"* Código","dense":"","rules":[
                      this.$validators.number.required,
                           function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 2); }
                  ]},model:{value:(_vm.value.cdOrgao),callback:function ($$v) {_vm.$set(_vm.value, "cdOrgao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cdOrgao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                      this.$validators.string.required,
                           function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"label":"* CNPJ","dense":"","placeholder":"99.999.999/9999-99","rules":[
                    this.$validators.string.required,
                    this.$validators.string.cnpj
                  ]},model:{value:(_vm.value.cnpj),callback:function ($$v) {_vm.$set(_vm.value, "cnpj", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cnpj"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Contato")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Email","dense":"","rules":[
                    this.$validators.string.email,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 50); } ]},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) ####-####'),expression:"'(##) ####-####'"}],attrs:{"label":"Telefone","dense":""},model:{value:(_vm.value.telefone),callback:function ($$v) {_vm.$set(_vm.value, "telefone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.telefone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Localização")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('########'),expression:"'########'"}],attrs:{"label":"* CEP","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 8); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 8); } ]},model:{value:(_vm.value.cep),callback:function ($$v) {_vm.$set(_vm.value, "cep", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cep"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Cidade","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ],"value":_vm.value.cidade}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('lookup-dominio',{attrs:{"label":"* Estado","hidden-clear":true,"value":_vm.value.estado,"type":_vm.lookups.estado,"rules":[
                        this.$validators.string.required
                    ]}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Bairro","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.bairro),callback:function ($$v) {_vm.$set(_vm.value, "bairro", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.bairro"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Endereço","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.endereco),callback:function ($$v) {_vm.$set(_vm.value, "endereco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.endereco"}}):_c('v-text-field',{attrs:{"label":"* Endereço","dense":"","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.endereco),callback:function ($$v) {_vm.$set(_vm.value, "endereco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.endereco"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"Complemento","rules":[
                    function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.complemento),callback:function ($$v) {_vm.$set(_vm.value, "complemento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.complemento"}}):_c('v-text-field',{attrs:{"label":"Complemento","dense":"","rules":[
                      function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.complemento),callback:function ($$v) {_vm.$set(_vm.value, "complemento", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.complemento"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }